@tailwind base;
@tailwind components;
@tailwind utilities;

.habit-tracker {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  height: 100vh;
}
