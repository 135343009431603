.habit-tracker input[type='checkbox'] {
    transform: scale(1.5); /* Increase checkbox size */
    margin: 2;
    vertical-align: middle;
  }

  .habit-tracker th img {
    width: 50px;
    height: 50px;
    padding: 6px;
  }
  
  .habit-tracker th,
  .habit-tracker td {
    width: 50px;
    height: 50px;
    padding: 10;
    text-align: center;
    vertical-align: middle;
  }
  
  .habit-tracker td {
    padding: 10px;
  }

  .border-green {
    border-color: #69ff8e !important;
  }
  
  .border-orange {
    border-color: #FF9500 !important;
  }
  
  .border-red {
    border-color: #d00a00 !important;
  }
  

  
